import {graphql} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import {HeaderTag, Layout, PostView} from '../components/common';
import {OverlayContainer} from '../components/common/effects';
import {MetaData} from '../components/common/meta';
import FeaturedTags from '../components/extra/FeaturedTags';
import {GlobalStateContext} from '../context/GlobalState';

function getSubTagsFragment(siteConfig, collectionPath, tagTreeRoot, tag) {
    function findMyTag(tagTree) {
        let result;
        if (tagTree.children) {
            for (const child of tagTree.children) {
                if (child.tag.slug === tag.slug) {
                    return child;
                }
                result = findMyTag(child);
                if (result) {
                    break;
                }
            }
        }
        return result;
    }
    const myTag = findMyTag(tagTreeRoot);

    if (!myTag) {
        return ``;
    }

    if (!myTag.children) {
        return ``;
    }

    const slugs = myTag.children.map((child) => child.tag.slug).sort();

    return (
        <FeaturedTags title={``} collectionPath={collectionPath} tags={slugs} />
    );
}

/**
 * Tag page (/tag/:slug)
 *
 * Loads all posts for the requested tag incl. pagination.
 *
 */
const Tag = ({data, location, pageContext}) => {
    const tag = data.ghostTag;
    const posts = data.allGhostPost.edges;
    const config = data.site.siteMetadata;

    const subTags = getSubTagsFragment(
        config,
        pageContext.collectionPath,
        pageContext.tagTree,
        tag,
    );
    return (
        <GlobalStateContext.Consumer>
            {(g) => (
                <React.Fragment>
                    <MetaData data={data} location={location} type="series" />
                    <OverlayContainer
                        render={(overlay) => (
                            <Layout
                                tags={[tag]}
                                overlay={overlay}
                                header={
                                    <HeaderTag
                                        overlay={overlay}
                                        tag={tag}
                                        numberOfPosts={pageContext.totalPosts}
                                    />
                                }
                            >
                                <div style={{paddingTop: 20}}>{subTags}</div>
                                <PostView
                                    globalState={g}
                                    pageContext={pageContext}
                                    posts={posts}
                                />
                            </Layout>
                        )}
                    />
                </React.Fragment>
            )}
        </GlobalStateContext.Consumer>
    );
};

Tag.propTypes = {
    data: PropTypes.shape({
        ghostTag: PropTypes.object.isRequired,
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
};

export default Tag;

export const pageQuery = graphql`
    query GhostTagQuery(
        $postIds: [String!]!
        $slug: String!
        $limit: Int!
        $skip: Int!
    ) {
        site {
            siteMetadata {
                ...SiteMetadataFields
            }
        }
        ghostTag(slug: {eq: $slug}) {
            ...GhostTagFields
        }
        allGhostPost(
            filter: {id: {in: $postIds}, tags: {elemMatch: {slug: {eq: $slug}}}}
            limit: $limit
            skip: $skip
            sort: {fields: [featured, published_at], order: [DESC, DESC]}
        ) {
            edges {
                node {
                    ...GhostPostFieldsForIndex
                }
            }
        }
    }
`;
